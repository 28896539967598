import React from 'react';

import { Icon, IconProps } from '../Icon';

interface IconCheckboxProps extends IconProps {
    selected?: boolean
}

export const IconCheckbox = (props: IconCheckboxProps) => {
    return <Icon id="IconCheckbox" viewBox="0 0 20 20" {...props}>
        {
            props.selected ?
                <path fillRule="evenodd" clipRule="evenodd" d="M5 2.5C3.61929 2.5 2.5 3.61929 2.5 5V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V5C17.5 3.61929 16.3807 2.5 15 2.5H5ZM14.5044 7.62944C14.7485 7.38536 14.7485 6.98964 14.5044 6.74556C14.2604 6.50148 13.8646 6.50148 13.6206 6.74556L8.4375 11.9286L6.37944 9.87056C6.13536 9.62648 5.73964 9.62648 5.49556 9.87056C5.25148 10.1146 5.25148 10.5104 5.49556 10.7544L8.4375 13.6964L14.5044 7.62944Z" /> :
                <path fillRule="evenodd" clipRule="evenodd" d="M15 3.75H5C4.30964 3.75 3.75 4.30964 3.75 5V15C3.75 15.6904 4.30964 16.25 5 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15V5C16.25 4.30964 15.6904 3.75 15 3.75ZM5 2.5C3.61929 2.5 2.5 3.61929 2.5 5V15C2.5 16.3807 3.61929 17.5 5 17.5H15C16.3807 17.5 17.5 16.3807 17.5 15V5C17.5 3.61929 16.3807 2.5 15 2.5H5Z" />
        }
    </Icon>;
};

export default IconCheckbox;
