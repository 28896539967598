import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconArrowLeft = (props: IconProps) => {
    return <Icon  id="IconArrowLeft" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.60651 5.79289C9.99703 6.18341 9.99704 6.81658 9.60651 7.2071L5.31366 11.5H20.8995C21.4517 11.5 21.8995 11.9477 21.8995 12.5C21.8995 13.0523 21.4517 13.5 20.8995 13.5H5.31366L9.60651 17.7929C9.99704 18.1834 9.99703 18.8166 9.60651 19.2071C9.21598 19.5976 8.58282 19.5976 8.19229 19.2071L2.19235 13.2071C1.80183 12.8166 1.80183 12.1834 2.19235 11.7929L8.19229 5.7929C8.58282 5.40237 9.21598 5.40237 9.60651 5.79289Z" />
    </Icon>;
};

export default IconArrowLeft;
