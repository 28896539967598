import React, { createContext, Dispatch, ReactNode, RefObject, SetStateAction, useCallback, useState, } from 'react';

import { IFilteredBy, IOption, IOrderedBy, OrderDirection } from '@funfarm/kit/types';
import { useTable } from '@funfarm/kit/Table/useTable';


export interface IProviderProps {
    tableRef: null | RefObject<HTMLDivElement>,
    children?: ReactNode,
    order?: IOrderedBy, // default
    orderedBy?: IOrderedBy, // current
    orderBy?: (fieldName: string, orderDirection: OrderDirection) => void,
    defaultOrderedBy?: string,
    defaultOrderedType?: OrderDirection,
    filter?: IFilteredBy,
    filteredBy?: IFilteredBy,
    filterBy?: (fieldName: string, filterValues?: IFilteredBy) => void,
    clearFilters?: () => void,
    filterValues?: {
        [key: string]: IOption[]
    },
    addFilterValues?: (fieldName: string, options: IOption[]) => void,
    tableColumns?: string[],
    setTableColumns?: Dispatch<SetStateAction<string[]>>,
    rowsChecked?: (string | number)[],
    setRowsChecked?: Dispatch<SetStateAction<string[]>>,
}

const defaultContextValue = {
    tableRef: null,
};

const TableContext = createContext<IProviderProps>(defaultContextValue);

const TableProvider = (props: IProviderProps) => {
    const {
        tableRef,
        orderBy, orderedBy, filterBy, filteredBy, clearFilters,
        rowsChecked, setRowsChecked
    } = props;

    const [filterValues, setFilterValues] = useState<IProviderProps['filterValues']>();
    const [tableColumns, setTableColumns] = useState<string[]>([]);


    const addFilterValues = useCallback((fieldName: string, options: IOption[]) => {
        setFilterValues(prevValues => ({ ...prevValues, [fieldName]: options }));
    }, [setFilterValues]);


    return (
        <TableContext.Provider value={{
            ...defaultContextValue,
            tableRef,
            orderedBy, orderBy,
            filteredBy, filterBy, filterValues, addFilterValues, clearFilters,
            tableColumns, setTableColumns,
            rowsChecked, setRowsChecked
        }}>
            {props.children}
        </TableContext.Provider>
    );
};


export { TableProvider, useTable, TableContext };
