import React, { CSSProperties, MouseEventHandler, ReactElement, ReactNode, useEffect, useRef } from "react";
import classNames from 'classnames';

import { Backdrop, Button, IconClose, Portal, useOnClickOutside } from '@funfarm/kit';

import css from './dialog.module.scss';


interface IProps {
    id?: string,
    header?: string,
    backdrop?: boolean,
    open: boolean,
    className?: string,
    style?: CSSProperties,
    buttons?: ReactElement[],
    onClose?: () => void,
    children?: ReactNode,
    actions?: Record<string, (() => boolean | void) | MouseEventHandler<HTMLButtonElement>>,
    wide?: boolean,
}

export const Dialog = (props: IProps) => {
    const {
        id = 'dialog-portal',
        header, backdrop = true, open,
        onClose, buttons, actions,
        className, style, wide,
        ...rest
    } = props;
    const wrapperRef = useRef<HTMLDivElement>(null);

    // if onButtonClick returns false Dialog wouldn't close
    const handleClose = async (onButtonClick?: () => Promise<boolean>) => {
        let closeAfter;

        if(typeof onButtonClick === 'function') {
            closeAfter = await onButtonClick();
        }

        if(onClose && closeAfter !== false)
            onClose();
    };


    useOnClickOutside(wrapperRef, () => handleClose());

    useEffect(() => {
        const body = document.body;
        if(open){
            body.style.overflow='hidden'
        } else{
            body.style.overflow='auto'
        }
    }, [open]);


    return (
        <>
            {
                open &&
                <Portal id={id}>
                    {
                        backdrop && <Backdrop open={open} />
                    }
                    <div className={classNames(
                        css.dialog,
                        wide && css.wide,
                        className,
                    )} {...rest} style={style} ref={wrapperRef}>
                        {
                            header !== undefined ?
                                <div className={css.header}>
                                    <h3 className={css.title}>{header}</h3>
                                    <IconClose onClick={onClose} size="large" className={css.icon} />
                                </div> :
                                null
                        }
                        {
                            props.children ?
                                <div className={css.body}>
                                    {props.children}
                                </div> :
                                null
                        }
                        {
                            // мутная тема
                            actions &&
                            <div className={css.actions}>
                                {
                                    Object.keys(actions).map((action) => {
                                        return (
                                            <>
                                                <Button onClick={() => actions[action]} view="outlined">{action}</Button>,
                                                <Button key="delete" onClick={() => actions[action]}>{action}</Button>
                                            </>
                                        );
                                        // return React.cloneElement(
                                        //     button,
                                        //     { key, onClick: () => handleClose(button.props.onClick) }
                                        // );
                                    })
                                }
                            </div>
                        }
                        {
                            buttons && buttons.length &&
                            <div className={css.actions}>
                                {
                                    buttons.map((button, key) => {
                                        return React.cloneElement(
                                            button,
                                            { key, onClick: () => handleClose(button.props.onClick) }
                                        );
                                    })
                                }
                            </div>
                        }
                    </div>
                </Portal>
            }
        </>
    );
};
