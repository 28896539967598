import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { roundDecimal } from "@funfarm/kit";
import { EColors } from '@funfarm/kit/types';

import css from './bar.module.scss';


interface IProps {
    value?: number,
    amount?: number,
    className?: string,
    showValues?: boolean | 'none',
    label?: boolean | string,
    color?: EColors,
    points?: number[],
    pointsLabels?: string[],
    inverse?: boolean,
    view?: 'default' | 'solid'
}

export const Bar = (props: IProps) => {
    const { t } = useTranslation();
    const {
        className, showValues, label = true,
        points, pointsLabels = ['falling', 'no change', 'rising'],
        inverse, view = 'default',
        amount = 100, value = 0,
        ...rest
    } = props;
    let { color = EColors.default } = rest;

    const progress = Math.min(value / amount * 100, 100);
    const per = inverse ? 100 - progress : Math.max(progress,0);
    const pointsPositions = useMemo(() => points?.map(point => Math.min(Math.round(point / amount * 100 * 100) / 100, 100)), [amount, points]);

    // какое-то мясо
    const currentPointLabel: { label: string, position: number, foundIndex: number } | null = useMemo(() => {
        if(!points || !pointsLabels || !pointsPositions)
            return null;

        const foundIndex = points?.findIndex((point) => value < point);

        if(foundIndex !== -1)
            return { label: pointsLabels[foundIndex], position: Math.round((pointsPositions[foundIndex] + (pointsPositions[foundIndex - 1] ?? 0)) / 2), foundIndex };

        return { label: pointsLabels[pointsLabels.length - 1], position: Math.round((pointsPositions[pointsPositions.length - 1] + 100) / 2), foundIndex };
    }, [value, points, pointsLabels, pointsPositions]);


    color = points ?
        (
            value <= points[0] ?
                EColors.red :
                value <= points[1] ?
                    EColors.yellow :
                    EColors.green
        ) : color;


    return (
        <div className={classNames(css.bar, css[color], className)}>
            <div className={css.back} style={{ width: view === 'solid' ? `calc(${per}% - 4em)` : '100%' }}>
                <div className={css.value} style={{ width: `${view === 'solid' ? 100 : value ? per : 0}%`, flexGrow: `${per}%` }}></div>
                {
                    points &&
                    pointsPositions &&
                    pointsPositions.map((point) => {
                        return (
                            point > 0 &&
                            <div key={point} className={css.point} style={{ left: `${point}%` }}>
                                <span className={css.tick} />
                                {/*<span className={css.tickLabel}>{points[i]}</span>*/}
                            </div>
                        );
                    })
                }
                {
                    pointsPositions &&
                    currentPointLabel &&
                    <span className={css.pointLabel} style={{ left: `${currentPointLabel.position}%` }}>{t(currentPointLabel.label)}</span>
                }
            </div>
            {
                label && showValues !== 'none' &&
                <div className={css.label}>
                    {
                        (typeof (label as string | boolean) === 'string') ?
                            label :
                            showValues ?
                                <>
                                    {roundDecimal(value)}
                                    {view !== 'solid' && `/ ${roundDecimal(amount)}`}
                                </> :
                                `${per.toFixed(0)}%`
                    }
                </div>
            }
        </div>
    );
};
