import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Button, Error, IconChevronLeft } from '@funfarm/kit';

import type { IRoute } from 'types';

import { useRoutes } from 'components/Routes';

import { NavigationProvider } from './NavigationProvider';

import css from './layout.module.scss';


interface IProps {
    children?: ReactNode,
}

export const Layout = React.memo((props: IProps) => {
    const routes: IRoute[] = useRoutes();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const routesStack = matchRoutes(routes, location)?.reverse();

    let permission = true;
    let layout = undefined;

    if(routesStack) {
        permission = !routesStack[0].route.handle ||
            routesStack[0].route.handle && (routesStack[0].route.handle.permission || routesStack[0].route.handle.permission === undefined);

        layout = routesStack[0].route.handle?.layout;
    }


    if(!permission)
        return <Error
            status={403}
            statusText="Forbidden"
            message="Permission denied, try to go back"
            layout
            button={<Button onClick={() => navigate('/')} label={t('Go back')} view="outlined" iconLeft={<IconChevronLeft />} />} />
        // throw new Error('Permission denied');


    // show Navigation and then next Layout
    return (
        <NavigationProvider>
            <div className={css.wrapper}>
                <div className={css.container}>
                    {
                        // show Header and then next Layout
                        props.children ??
                        <>
                            {
                                layout ?? <Outlet />
                            }
                        </>
                    }
                </div>
            </div>
        </NavigationProvider>
    );
});
