import { useTranslation } from 'react-i18next';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
    useParams,
} from 'react-router-dom';

import {
    IconAlignTextLeft,
    IconChartLine,
    IconDocumentSearch,
    IconPPT,
} from '@funfarm/kit';

import { preparePath } from 'helpers';
import { IRoute } from 'types';

import { Info } from 'components/Info';
import { ErrorLayout, Layout, NavigationLayout } from 'components/Layout';
import { Player } from 'components/Player';
import { Select } from 'components/Select';
import { Statistic } from 'components/Statistic';

export const useRoutes = () => {
    const { t } = useTranslation();
    const _params = useParams();

    const routes: IRoute[] = [
        {
            path: '/',
            Component: Layout,
            ErrorBoundary: ErrorLayout,
            handle: {
                visible: false,
                layout: <NavigationLayout />,
            },
            children: [
                {
                    index: true,
                    element: (
                        <Navigate to={import.meta.env.FF_BASE_PATH} replace />
                    ),
                    handle: {
                        visible: false,
                    },
                },
                {
                    path: import.meta.env.FF_BASE_PATH,
                    Component: Info,
                    handle: {
                        name: t('Info'),
                        icon: <IconPPT />,
                        crumb: false,
                    },
                },
                {
                    path: 'player',
                    Component: Player,
                    handle: {
                        name: t('Rang changes'),
                        icon: <IconChartLine />,
                        crumb: false,
                    },
                },
                {
                    path: 'statistic',
                    Component: Statistic,
                    handle: {
                        name: t('Statistic'),
                        icon: <IconAlignTextLeft />,
                        crumb: false,
                    },
                },
                {
                    path: 'select',
                    Component: Select,
                    handle: {
                        name: t('Select'),
                        icon: <IconDocumentSearch />,
                        crumb: false,
                    },
                },
            ],
        },
    ];

    return preparePath(routes);
};

export const Routes = () => {
    const routes: IRoute[] = useRoutes();

    return <RouterProvider router={createBrowserRouter(routes)} />;
};
