import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconChartLine = (props: IconProps) => {
    return <Icon id="IconChartLine" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4299 2.09713C21.9286 2.33457 22.1403 2.93129 21.9029 3.42992L16.9029 13.9299C16.7672 14.2148 16.505 14.419 16.1955 14.4807C15.886 14.5424 15.5656 14.4544 15.331 14.2433L11.3071 10.6218L7.89443 17.4472C7.64744 17.9412 7.04676 18.1414 6.55279 17.8944C6.05881 17.6474 5.85858 17.0468 6.10557 16.5528L10.1056 8.55278C10.2452 8.27355 10.5072 8.07554 10.8139 8.01745C11.1207 7.95936 11.4369 8.04785 11.669 8.25669L15.6731 11.8605L20.0971 2.57006C20.3346 2.07142 20.9313 1.85968 21.4299 2.09713ZM3 1.99999C3.55228 1.99999 4 2.4477 4 2.99999V20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V2.99999C2 2.4477 2.44772 1.99999 3 1.99999Z" />
    </Icon>;
};

export default IconChartLine;
