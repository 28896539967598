import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconAlignTextLeft = (props: IconProps) => {
    return <Icon id="IconAlignTextLeft" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 4.5C4.22386 4.5 4 4.72386 4 5V20C4 20.2761 4.22386 20.5 4.5 20.5H19.5C19.7761 20.5 20 20.2761 20 20V5C20 4.72386 19.7761 4.5 19.5 4.5H4.5ZM2 5C2 3.61929 3.11929 2.5 4.5 2.5H19.5C20.8807 2.5 22 3.61929 22 5V20C22 21.3807 20.8807 22.5 19.5 22.5H4.5C3.11929 22.5 2 21.3807 2 20V5ZM6 8C6 7.44772 6.44772 7 7 7H17C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H7C6.44772 9 6 8.55228 6 8ZM6 12.5C6 11.9477 6.44772 11.5 7 11.5H13C13.5523 11.5 14 11.9477 14 12.5C14 13.0523 13.5523 13.5 13 13.5H7C6.44772 13.5 6 13.0523 6 12.5ZM6 17C6 16.4477 6.44772 16 7 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H7C6.44772 18 6 17.5523 6 17Z" />
    </Icon>;
};

export default IconAlignTextLeft;
