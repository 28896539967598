import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";

import { formatNumber, IError, Paper, roundDecimal, Skeleton } from "@funfarm/kit";

import { colorTemperature } from "helpers";
import { IPreflopSummary } from "types";
import { getPreflopSummary } from "actions";

import { Helper } from "components/Helper";

const colorPrecision = 5;

export const Indicators = () => {
    const { t } = useTranslation();

    const { isLoading, data } = useQuery<IPreflopSummary, IError>({
        queryKey: ["preflop", "summary"],
        queryFn: getPreflopSummary
    });

    return (
        <>
            <Paper className="indicator">
                <h4 className="header">{t("EV weighted")}</h4>
                {
                    isLoading ?
                        <Skeleton size="xsmall" /> :
                        <div className="subtitle">
                            {t("Avg at rang")}{" "}
                            {roundDecimal(data?.rang_avg_wr_ev_3m)}
                        </div>
                }
                {
                    isLoading ?
                        <Skeleton size="xxxlarge" /> :
                        <div className={classNames(
                            'value',
                            `color_${colorTemperature(data?.wr_ev_100k ?? 0, data?.rang_avg_wr_ev_3m ?? 0, colorPrecision)}`
                        )}>
                            {roundDecimal(data?.wr_ev_100k)}
                        </div>
                }
                {
                    isLoading ?
                        <Skeleton size="xsmall" /> :
                        <div className="caption">n/a
                            {/*formatNumber(data?.hand_count_hard_100k)}{' '}
                            {t('hand', { count: data?.hand_count_hard_100k })*/}
                        </div>
                }
            </Paper>

            <Paper className="indicator">
                <h4 className="header">
                    {t("EV hard")}
                    <Helper path={`statEVHigh`} />
                </h4>
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="subtitle">
                        {t("Avg at rang")}{" "}
                        {roundDecimal(data?.rang_avg_ev_hard_3m)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xxxlarge" />
                ) : (
                    <div
                        className={classNames(
                            "value",
                            `color_${colorTemperature(data?.ev_hard_100k ?? 0, data?.rang_avg_ev_hard_3m ?? 0, colorPrecision)}`
                        )}
                    >
                        {roundDecimal(data?.ev_hard_100k)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="caption">
                        {formatNumber(data?.hand_count_hard_100k)}{" "}
                        {t("hand", { count: data?.hand_count_hard_100k })}
                    </div>
                )}
            </Paper>

            <Paper className="indicator">
                <h4 className="header">{t("EV medium")}</h4>
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="subtitle">
                        {t("Avg at rang")}{" "}
                        {roundDecimal(data?.rang_avg_ev_medium_3m)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xxxlarge" />
                ) : (
                    <div
                        className={classNames(
                            "value",
                            `color_${colorTemperature(data?.ev_medium_100k ?? 0, data?.rang_avg_ev_medium_3m ?? 0, colorPrecision)}`
                        )}
                    >
                        {roundDecimal(data?.ev_medium_100k)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="caption">
                        {formatNumber(data?.hand_count_medium_100k)}{" "}
                        {t("hand", { count: data?.hand_count_medium_100k })}
                    </div>
                )}
            </Paper>

            <Paper className="indicator">
                <h4 className="header">{t("EV easy")}</h4>
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="subtitle">
                        {t("Avg at rang")}{" "}
                        {roundDecimal(data?.rang_avg_ev_easy_3m)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xxxlarge" />
                ) : (
                    <div
                        className={classNames(
                            "value",
                            `color_${colorTemperature(data?.ev_easy_100k ?? 0, data?.rang_avg_ev_easy_3m ?? 0, colorPrecision)}`
                        )}
                    >
                        {roundDecimal(data?.ev_easy_100k)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="caption">
                        {formatNumber(data?.hand_count_easy_100k)}{" "}
                        {t("hand", { count: data?.hand_count_easy_100k })}
                    </div>
                )}
            </Paper>

            <Paper className="indicator">
                <h4 className="header">
                    {t("EV 10lvl+")}
                    <Helper path={`statEV10`} />
                </h4>
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="subtitle">
                        {t("Avg at rang")}{" "}
                        {roundDecimal(data?.rang_avg_ev_late_3m)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xxxlarge" />
                ) : (
                    <div
                        className={classNames(
                            "value",
                            `color_${colorTemperature(data?.ev_late_100k ?? 0, data?.rang_avg_ev_late_3m ?? 0, colorPrecision)}`
                        )}
                    >
                        {roundDecimal(data?.ev_late_100k)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="caption">
                        {formatNumber(data?.hand_count_late_100k)}{" "}
                        {t("hand", { count: data?.hand_count_late_100k })}
                    </div>
                )}
            </Paper>

            <Paper className="indicator">
                <h4 className="header">{t("EV rooms w stats")}</h4>
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="subtitle">
                        {t("Avg at rang")}{" "}
                        {roundDecimal(data?.rang_avg_ev_stat_rooms_3m)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xxxlarge" />
                ) : (
                    <div
                        className={classNames(
                            "value",
                            `color_${colorTemperature(data?.ev_stat_rooms_100k ?? 0, data?.rang_avg_ev_stat_rooms_3m ?? 0, colorPrecision)}`
                        )}
                    >
                        {roundDecimal(data?.ev_stat_rooms_100k)}
                    </div>
                )}
                {
                    isLoading ?
                        <Skeleton size="xsmall" /> :
                        <div className="caption">
                            {formatNumber(data?.hand_count_stat_rooms_100k)}{" "}
                            {t("hand", { count: data?.hand_count_stat_rooms_100k })}
                        </div>
                }
            </Paper>

            <Paper className="indicator">
                <h4 className="header">{t("EV rooms wo stats")}</h4>
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="subtitle">
                        {t("Avg at rang")}{" "}
                        {roundDecimal(data?.rang_avg_ev_no_stat_rooms_3m)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xxxlarge" />
                ) : (
                    <div
                        className={classNames(
                            "value",
                            `color_${colorTemperature(data?.ev_no_stat_rooms_100k ?? 0, data?.rang_avg_ev_no_stat_rooms_3m ?? 0, colorPrecision)}`
                        )}
                    >
                        {roundDecimal(data?.ev_no_stat_rooms_100k)}
                    </div>
                )}
                {isLoading ? (
                    <Skeleton size="xsmall" />
                ) : (
                    <div className="caption">
                        {formatNumber(data?.hand_count_no_stat_rooms_100k)}{" "}
                        {t("hand", { count: data?.hand_count_no_stat_rooms_100k })}
                    </div>
                )}
            </Paper>
        </>
    );
};
