import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import { IconNoImage, Image, Loader, Paper } from '@funfarm/kit';

import css from './statistic.module.scss';


interface IProps {
}

const video = {
    name: 'Some name',
    url: '/some_video.mp4',
    picture: '/some_url'
};

export const Video = (props: IProps) => {
    const {} = props;
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        if(isPlaying && videoRef.current) {
            videoRef.current.play();
        }
    }, [isPlaying]);


    const playVideo = (_: MouseEvent<HTMLElement>) => {
        setIsPlaying(true);
    };


    return (
        <Paper className={css.video}>
            {
                isPlaying ?
                    <>
                        {
                            isLoading &&
                            <Loader layout />
                        }
                        <video muted className={css.player} ref={videoRef} loop onLoadedData={() => setLoading(false)}>
                            <source src={video.url} type="video/mp4" />
                            Ваш браузер не поддерживает видео.
                        </video>
                    </> :
                    <Image onClick={playVideo} className={css.preview} src={video?.picture ?? ''} alt={video.name} fallBack={<IconNoImage button={false} className={css.fail} />} />
            }
        </Paper>
    );
};
