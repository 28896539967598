import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconChevronDown = (props: IconProps) => {
    return <Icon  id="IconChevronDown" {...props}>
        <path d="M20.4853 8.73528C20.1924 8.44239 19.7175 8.44239 19.4246 8.73528L12 16.1599L4.57538 8.73528C4.28249 8.44239 3.80761 8.44239 3.51472 8.73528C3.22183 9.02817 3.22182 9.50305 3.51472 9.79594L11.4697 17.7509C11.7626 18.0438 12.2374 18.0438 12.5303 17.7509L20.4853 9.79594C20.7782 9.50305 20.7782 9.02817 20.4853 8.73528Z" />
    </Icon>;
};

export default IconChevronDown;
