import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@funfarm/kit';

import { ABIChart } from './ABIChart';
import { EVByMonthChart } from './EVByMonthChart';
import { GrowthByRanksChart } from './GrowthByRanksChart';
import { Indicators } from './Indicators';
import { MTTChart } from './MTTChart';

import css from './player.module.scss';


export const Player = () => {
    const { t } = useTranslation();


    return (
        <div className={css.pageGrid}>
            <Indicators />

            <Paper header={t('Growth by ranks')} className={css.chart}>
                <GrowthByRanksChart />
            </Paper>

            <Paper header={t('EV by month')} className={css.chart}>
                <EVByMonthChart />
            </Paper>

            <Paper header={t('ABI')} className={css.chart}>
                <ABIChart />
            </Paper>

            <Paper header={t('Distance')} className={css.chart}>
                <MTTChart />
            </Paper>
        </div>
    );
};
