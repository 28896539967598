import React from 'react';
import classNames from 'classnames';

import { ESizes } from '@funfarm/kit/types';

import css from './skeleton.module.scss';


interface IProps {
    size?: keyof typeof ESizes,
    className?: string,
}

export const Skeleton = (props: IProps) => {
    const { size = ESizes.medium, className } = props;

    return (
        <div
            className={classNames(
                css.skeleton,
                css[size],
                className
            )} />
    );
};
