import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconMenu = (props: IconProps) => {
    return <Icon id="IconMenu" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.97488 6.4751C2.97488 5.92281 3.4226 5.4751 3.97488 5.4751H19.9749C20.5272 5.4751 20.9749 5.92281 20.9749 6.4751C20.9749 7.02738 20.5272 7.4751 19.9749 7.4751H3.97488C3.4226 7.4751 2.97488 7.02738 2.97488 6.4751ZM2.97488 12.4751C2.97488 11.9228 3.4226 11.4751 3.97488 11.4751H19.9749C20.5272 11.4751 20.9749 11.9228 20.9749 12.4751C20.9749 13.0274 20.5272 13.4751 19.9749 13.4751H3.97488C3.4226 13.4751 2.97488 13.0274 2.97488 12.4751ZM2.97488 18.4751C2.97488 17.9228 3.4226 17.4751 3.97488 17.4751H19.9749C20.5272 17.4751 20.9749 17.9228 20.9749 18.4751C20.9749 19.0274 20.5272 19.4751 19.9749 19.4751H3.97488C3.4226 19.4751 2.97488 19.0274 2.97488 18.4751Z" />
    </Icon>;
};

export default IconMenu;
