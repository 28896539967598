import React, { ChangeEvent, useContext, useState } from 'react';

import { Checkbox, TableContext } from '@funfarm/kit';


interface IProps {
    rowId?: string | number
}

export const ActionCheckbox = (props: IProps) => {
    const { rowId } = props;
    const { rowsChecked, setRowsChecked } = useContext(TableContext);

    const [key, setKey] = useState<number>(0);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setRowsChecked &&
        setRowsChecked(prevRows => {
            if(value === 'all') {
                if(!prevRows.includes('all'))
                    return ['all'];
                else
                    return [];
            }

            if(prevRows.includes('all'))
                prevRows = [];

            return (
                prevRows.includes(value.toString()) ?
                    prevRows.filter(row => row !== value.toString()) :
                    [...prevRows, value.toString()]
            );
        });

        setKey(prevKey => prevKey + 1);
    };

    rowId &&
    console.log('RENDER', rowsChecked?.includes(rowId.toString()));

    return (
        <>
            {
                rowId ?
                    <Checkbox onChange={handleChange} value={rowId} key={`${key}${rowId}`} checked={rowsChecked?.includes(rowId.toString()) || rowsChecked?.includes('all')} /> :
                    <Checkbox onChange={handleChange} value="all" checked={rowsChecked?.includes('all')} />
            }
        </>
    );
};
