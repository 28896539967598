import axios, { AxiosError, AxiosInstance } from 'axios';

import type { IError, IErrorData } from '@funfarm/kit';

import { env } from 'env';
import { authStorage } from 'helpers';
// import i18n from 'helpers/i18n';


const ApiInstance = (baseURL?: string): AxiosInstance => {
    const _API = axios.create({
        baseURL: baseURL ?? env.FF_API_URL,
        responseType: 'json',
        withCredentials: false,
        timeout: 1000 * 30,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=40000',
        },
    });

    _API.interceptors.request.use(request => {
        request.headers.Authorization = `Bearer ${authStorage.getItem()}`;

        // let q = 1;
        // const [current, ...languages] = i18n.languages;
        //
        // request.headers['Accept-Language'] = `${current},${languages.map(lng => {
        //     q = q - 0.1;
        //     return `${lng};q=${q}`;
        // }).join(',')}`;

        return request;
    });

    _API.interceptors.response.use(
        (response) => {
            return response.data;
        },
        (error: AxiosError<IErrorData>) => {
            let errorData: IError = {
                code: error.code,
                message: error.message,
            };

            if(['ECONNABORTED', 'ERR_NETWORK'].includes(errorData.code as string))
                errorData.message = 'Something wrong with a network access, try again';

            if(error.response) {
                if([401, 403].includes(error.response.status))
                    window.location.reload();

                errorData.status = error.response.status;
                errorData.statusText = error.response.statusText;

                if(error.response.data) {
                    errorData = { ...errorData, ...error.response.data };

                    if(error.response.data.detail)
                        errorData.message = error.response.data.detail;
                }
            }

            if(import.meta.env.NODE_ENV === 'development')
                console.error('API errorData', errorData);

            return Promise.reject<IError>(errorData);
        }
    );

    return _API;
};

const API = ApiInstance();

export { ApiInstance, API };
