import React from 'react';

import css from './table.module.scss';


interface IProps {
}

export const Separator = (props: IProps) => {
    const { } = props;

    return (
        <div className={css.separator} />
    );
};

export default Separator;
