import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import css from './wide.module.scss';


interface IProps {
    children?: ReactNode,
}

export const WideLayout = React.memo((props: IProps) => {
    // Do not show Navigation and Breadcrumbs
    return (
        <div className={css.layout}>
            <div className={css.outlet}>
                {
                    props.children ?? <Outlet />
                }
            </div>
        </div>
    );
});
