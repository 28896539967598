import React, { ComponentProps } from 'react';

import { Icon } from '../Icon';


export const IconPPT = (props: ComponentProps<any>) => {
    return <Icon id="IconPPT" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1 4C1 3.44772 1.44772 3 2 3H22C22.5523 3 23 3.44772 23 4C23 4.55228 22.5523 5 22 5H21V17C21 17.5523 20.5523 18 20 18H14.4142L16.7071 20.2929C17.0976 20.6834 17.0976 21.3166 16.7071 21.7071C16.3166 22.0976 15.6834 22.0976 15.2929 21.7071L12 18.4142L8.70711 21.7071C8.31658 22.0976 7.68342 22.0976 7.29289 21.7071C6.90237 21.3166 6.90237 20.6834 7.29289 20.2929L9.58579 18H4C3.44772 18 3 17.5523 3 17V5H2C1.44772 5 1 4.55228 1 4ZM5 5V16H11.9993C11.9998 16 12.0002 16 12.0007 16H19V5H5ZM10.2929 7.29289C10.6834 6.90237 11.3166 6.90237 11.7071 7.29289L14.2071 9.79289C14.5976 10.1834 14.5976 10.8166 14.2071 11.2071L11.7071 13.7071C11.3166 14.0976 10.6834 14.0976 10.2929 13.7071C9.90237 13.3166 9.90237 12.6834 10.2929 12.2929L12.0858 10.5L10.2929 8.70711C9.90237 8.31658 9.90237 7.68342 10.2929 7.29289Z" />
    </Icon>;
};

export default IconPPT;
