import React, { ChangeEvent, ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { ELabelPosition, IEventTarget, IOption } from '@funfarm/kit/types';
import { IconChevronDown, IconChevronUp, IconSpinner, ISelectProps } from '@funfarm/kit';
import { SelectedBadges } from '@funfarm/kit/Select/SelectedBadges';

import css from './select.module.scss';


export interface IMultiSelectProps extends Omit<ISelectProps, 'value'> {
    value?: (string | number)[] | ISelectProps['value'];
    maxBadges?: number;
}

export const MultiSelect = forwardRef((props: IMultiSelectProps, _ref: ForwardedRef<HTMLInputElement>) => {
    const {
        name, label, labelPosition = ELabelPosition.top,
        error, displayError,
        required, disabled, readonly, placeholder,
        onFocus, onBlur, onChange,
        defaultLabel,
        className, style,
        options, loading,
        dataTestId,
        addonRight,
        maxBadges
    } = props;
    // value has to be a string
    const value = props.value ? props.value.toString() : '';

    const [internalValue, setInternalValue] = useState<(string | number)[]>([]);
    const [, setLabel] = useState<IMultiSelectProps['defaultLabel']>(defaultLabel ?? '');
    const [open, setOpen] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [currentOptions, setOptions] = useState<IOption[]>(options ?? []);

    const labelRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLInputElement>(null);


    const handleSetLabel = useCallback((optionValue: typeof value = value) => {
        const option = currentOptions.filter(option => (option.value.toString() === optionValue));

        if(option[0])
            setLabel(option[0].label);
        else
            setLabel(defaultLabel ?? optionValue);
    }, [currentOptions, value, defaultLabel]);


    useEffect(() => {
        // setValue(value);
        handleSetLabel(value);
    }, [value, handleSetLabel]);


    useEffect(() => {
        setOptions(options);
    }, [options, required]);


    useEffect(() => {
        handleSetLabel();
    }, [currentOptions, handleSetLabel]);


    const handleSetValue = useCallback((option: IOption) => {
        // это нужно чтобы форма получила новое значение
        if(option.disabled)
            return;

        if(valueRef.current) {
            valueRef.current.value = valueRef.current.value
                .split(',')
                .filter(v => v && v !== option.value)
                .concat(option.value.toString())
                .toString();

            const event = new Event('input', { bubbles: true });

            valueRef.current.dispatchEvent(event);
        }
        // setValue(option.value as string);
        // setLabel(option.label);
    }, []);


    const handleChange = (event: ChangeEvent<HTMLInputElement> | IEventTarget) => {
        // setValue(event.target.value as typeof value);

        if(onChange)
            onChange(event as ChangeEvent<HTMLInputElement>);
    };


    const handleOpen = useCallback(() => {
        if(readonly || disabled)
            return;

        setOpen(true);
    }, [disabled, readonly]);


    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);


    const handleFocus = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if(readonly || disabled)
            return;

        setFocus(true);
        labelRef.current?.focus();

        handleOpen();

        if(onFocus)
            onFocus(event);
    }, [disabled, readonly, handleOpen, onFocus]);


    const handleBlur = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFocus(false);

        handleClose();

        if(onBlur)
            onBlur(event);
    }, [handleClose, onBlur]);


    const updateValue = (newValue: (string | number)[]) => {
        if(valueRef.current) {
            setInternalValue(newValue);
            valueRef.current.value = newValue.join();

            const event = new Event('input', { bubbles: true });
            valueRef.current.dispatchEvent(event);
        }
    };


    const handleDelete = (optionValue: string | number) => {
        return () => {
            const newValue = internalValue.filter((itemValue: string | number): boolean => String(itemValue) !== String(optionValue));

            updateValue(newValue);
        };
    };

    // const renderBadges = () => {
    //     if(!value)
    //         return null;
    //
    //     return value?.split(',').map(v => <Badge key={v} label={findLabelByValue(v)} disabled={disabled || readonly} onDelete={() => handleRemoveValue(v)} size="small" />);
    // };
    //
    //
    // const findLabelByValue = useCallback((targetValue: string) => {
    //     for(let i = 0; i < options.length; i++) {
    //         if(options[i].value.toString() === targetValue) {
    //             return options[i].label;
    //         }
    //     }
    //
    //     return targetValue;
    // }, [options])


    return (
        <div className={classNames(
            css.select,
            (error && css['error']),
            (disabled && css['disabled']),
            (readonly && css['readonly']),
            css.labelPosition,
            css[labelPosition],
            className,
        )} style={style}>
            {
                label && labelPosition !== ELabelPosition.inside &&
                <label htmlFor={name} className={classNames(css.label, css[labelPosition])}>
                    {label}
                    {
                        required &&
                        <span className={css.mark}>*</span>
                    }
                </label>
            }
            <div className={css.errorWrapper}>
                <div className={css.addonWrapper}>
                    <div className={classNames(
                        css.wrapper,
                        (disabled && css.wrapperDisabled),
                        (readonly && css.wrapperReadonly),
                        (error && css.wrapperError),
                        (focus && css.focus)
                    )}>
                        <input id={name} readOnly type="hidden" name={name} value={value} disabled={disabled} onInput={(e) => handleChange(e as ChangeEvent<HTMLInputElement>)} data-testid={dataTestId} ref={valueRef} />
                        <div className={css.badgesWrapper}>
                            <SelectedBadges values={internalValue} maxBadges={maxBadges} disabled={disabled} handleDelete={handleDelete} readonly={readonly} />
                        </div>
                        <input id={name + '-label'} name={name + '-label'} disabled={disabled} required={required} readOnly placeholder={placeholder} onFocus={handleFocus} onBlur={handleBlur} type="text" autoComplete="off" ref={labelRef} />
                        {
                            loading ?
                                <IconSpinner className={classNames('spin', css.chevron)} /> :
                                open ?
                                    <IconChevronUp className={css.chevron} onClick={() => handleBlur({} as ChangeEvent<HTMLInputElement>)} /> :
                                    <IconChevronDown className={css.chevron} onClick={() => handleFocus({} as ChangeEvent<HTMLInputElement>)} />
                        }
                        <ul className={classNames(css.options, (open && css.open))}>
                            {
                                currentOptions.map((option) => (
                                    <li key={option.value} className={classNames(css.option, option.disabled && css.disabled)} onMouseDown={() => handleSetValue(option)}>{option.label}</li>
                                ))
                            }
                        </ul>
                    </div>
                    {
                        addonRight &&
                        React.cloneElement(addonRight, { className: classNames(addonRight.props.className, css['addonRight']) })
                    }
                </div>
                {
                    displayError && error &&
                    <p className={css.error}>{error}</p>
                }
            </div>
        </div>
    );
});
