import React from 'react';

import { Indicators } from './Indicators';
import { Preflop } from './Preflop';
import { Video } from './Video';

import css from './statistic.module.scss';


export const Statistic = () => {
    return (
        <div className={css.pageGrid}>
            <Indicators />

            <Preflop />

            <Preflop />

            <Video />
        </div>
    );
};
