import React, { useContext } from 'react';
import classNames from 'classnames';

import { IOrderedBy, OrderDirection } from '@funfarm/kit/types';
import { IconSortDown, IconSortUp, TableContext } from '@funfarm/kit';

import css from './sort.module.scss';


interface IProps {
    fieldName: string,
    orderedBy?: IOrderedBy,
    orderBy?: (fieldName: string, orderDirection: OrderDirection) => void
}


export const ActionSort = (props: IProps) => {
    const { fieldName, orderedBy: externalOrderedBy, orderBy: externalOrderBy } = props;
    let { orderedBy, orderBy } = useContext(TableContext);

    orderBy = externalOrderBy ?? orderBy;
    orderedBy = externalOrderedBy ?? orderedBy;


    return (
        orderedBy && orderedBy[fieldName] === OrderDirection.asc ?
            <IconSortDown className={classNames(css.sort, css.active)} onClick={() => orderBy && orderBy(fieldName, OrderDirection.desc)} /> :
            orderedBy && orderedBy[fieldName] === OrderDirection.desc ?
                <IconSortUp className={classNames(css.sort, css.active)} onClick={() => orderBy && orderBy(fieldName, OrderDirection.asc)} /> :
                <IconSortDown className={css.sort} onClick={() => orderBy && orderBy(fieldName, OrderDirection.asc)} />
    );
};
