export const createModalRoot = ({ id, className }: { id: string, className?: string } = { id: 'modal-root' }) => {
    const existsContainer = document.getElementById(id);

    if(existsContainer)
        return existsContainer;

    const domContainer = document.createElement('div');

    if(id)
        domContainer.id = id;

    if(className)
        domContainer.className = className;

    document.body.appendChild(domContainer);

    return domContainer;
};
