import React, { useCallback, useContext } from 'react';

import { IconClose, IconMenu, IconSignOut, useGlobalState } from '@funfarm/kit';

import { authStorage } from 'helpers';
import { IUser } from 'types';

import { NavigationContext } from 'components/Layout/NavigationProvider';

import css from './header.module.scss';


export const Header = () => {
    const { mobileExpanded, setMobileExpanded } = useContext(NavigationContext);
    const [currentUser] = useGlobalState<IUser>('user');


    const logOut = useCallback(() => {
        authStorage.removeItem();
        window.location.reload();
    }, []);


    const handleMobileToggle = useCallback(() => {
        setMobileExpanded && setMobileExpanded(exp => !exp);
    }, [setMobileExpanded]);

    return (
        <header className={css.container}>
            <div className={css.envBlock}>
            </div>

            <div className={css.navBlock}>
            </div>

            <div className={css.userBlock}>
                <div className={css.name}>{currentUser?.name}</div>
            </div>
            {
                mobileExpanded ?
                    <IconClose onClick={handleMobileToggle} size="large" className={css.mobileMenuIcon} /> :
                    <IconMenu onClick={handleMobileToggle} size="large" className={css.mobileMenuIcon} />
            }
            <IconSignOut onClick={logOut} size="xlarge" className={css.logout} />
        </header>
    );
};
