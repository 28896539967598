import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconCloseCircle = (props: IconProps) => {
    return <Icon id="IconCloseCircle" {...props}>
        <path d="M8.28769 9.34835C7.99479 9.05546 7.99479 8.58058 8.28769 8.28769C8.58058 7.9948 9.05545 7.9948 9.34835 8.28769L12 10.9393L14.6516 8.28769C14.9445 7.9948 15.4194 7.9948 15.7123 8.28769C16.0052 8.58058 16.0052 9.05546 15.7123 9.34835L13.0607 12L15.7123 14.6517C16.0052 14.9445 16.0052 15.4194 15.7123 15.7123C15.4194 16.0052 14.9445 16.0052 14.6516 15.7123L12 13.0607L9.34835 15.7123C9.05545 16.0052 8.58058 16.0052 8.28769 15.7123C7.99479 15.4194 7.99479 14.9445 8.28769 14.6517L10.9393 12L8.28769 9.34835Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25C7.44365 20.25 3.75 16.5563 3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5563 3.75 20.25 7.44365 20.25 12Z" />
    </Icon>;
};

export default IconCloseCircle;
