import React, { ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { Backdrop, navigationStorage, useOnClickOutside } from '@funfarm/kit';

import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Navigation } from 'components/Navigation';

import { NavigationContext } from './NavigationProvider';

import css from './navigation.module.scss';
// import { IconDoubleLeft, IconDoubleRight } from '@funfarm/kit';


interface IProps {
    children?: ReactNode,
}

export const NavigationLayout = React.memo((props: IProps) => {
    const { mobileExpanded, setMobileExpanded } = useContext(NavigationContext);
    const navRef = useRef(null);
    const navigate = useNavigate();

    const [expanded, setExpanded] = useState<boolean>(true); // (!navigationStorage.getItem());

    useOnClickOutside(navRef, () => setMobileExpanded && setMobileExpanded(false));


    const _handleExpand = useCallback(() => {
        setExpanded(prev => {
            if(prev)
                navigationStorage.removeItem();
            else
                navigationStorage.setItem('expanded');

            return !prev;
        });
    }, []);


    // show Navigation and then next Layout
    return (
        <>
            <Backdrop open={mobileExpanded} animation className={css.backdrop} />
            <div className={classNames(css.navigation, expanded && css.expanded, mobileExpanded && css.mobileExpanded)} ref={navRef}>
                <img src="/img/logo.svg" className={css.logo} title="FunFarm" alt="FunFarm" onClick={() => navigate('/')} />

                <Navigation {...{ expanded, setExpanded }} />
                {/*<div className={css.navigationState} onClick={handleExpand}>*/}
                {/*    {*/}
                {/*        expanded ?*/}
                {/*            <IconDoubleLeft size="large" /> :*/}
                {/*            <IconDoubleRight size="large" />*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
            <div className={classNames(css.layout, expanded && css.expanded)}>
                <Header />

                <div className={css.outlet}>
                    {
                        props.children ?? <Outlet />
                    }
                </div>

                <Footer />
            </div>
        </>
    );
});
