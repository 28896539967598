import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconGrowUp = (props: IconProps) => {
    return <Icon id="IconGrowUp" viewBox="0 0 512 512" {...props}>
        <polygon points="256.5,64.5 64.5,256.5 176.5,256.5 176.5,380 336.5,380 336.5,256.5 448.5,256.5" />
    </Icon>;
};

export default IconGrowUp;
