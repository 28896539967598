import React, { HTMLAttributes, useRef } from 'react';
import classNames from 'classnames';

// import { useTable } from './TableProvider';
import css from './table.module.scss';


interface Props extends HTMLAttributes<HTMLDivElement> {
    nowrap?: boolean,
    align?: string,
    valign?: string,
    first?: boolean
}

export const TableHeader = (props: Props) => {
    const headerRef = useRef(null);
    // const { tableRef } = useTable();

    const {
        nowrap, valign, align, first,
        className, style,
        ...rest
    } = props;

    return (
        <div {...rest}
            ref={headerRef}
            className={classNames(
                css.row,
                css.header,
                (first && css.firstChild),
                (valign && css[`valign-${valign}`]),
                (align && css[`align-${align}`]),
                (nowrap && css.nowrap),
                className,
            )}
            style={style}
        >
            {props.children}
        </div>
    );
};

export default TableHeader;
