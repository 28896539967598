import React, { createContext, Dispatch, ReactNode, RefObject, SetStateAction, useState,  } from 'react';


export interface IProviderProps {
    tabsRef: null | RefObject<HTMLDivElement>,
    children?: ReactNode,
    tabChecked?: string | number,
    setTabCheckedState?: Dispatch<SetStateAction<string | number>>,
    setTabChecked?: (tabId: string | number) => void,
    label?: string,
    setLabel?: Dispatch<SetStateAction<string>>,
    onChange?: (tabIndex: number | string) => void,
}

const defaultContextValue = {
    tabsRef: null,
};

const TabsContext = createContext<IProviderProps>(defaultContextValue);

const TabsProvider = (props: IProviderProps) => {
    const {
        tabsRef, tabChecked, onChange
    } = props;

    const [tabCheckedState, setTabCheckedState] = useState<string | number>(tabChecked ?? '');
    const [label, setLabel] = useState<string>('');


    const setTabChecked = (tabId: string | number) => {
        setTabCheckedState(tabId);

        onChange && onChange(tabId);
    };


    return (
        <TabsContext.Provider value={{
            ...defaultContextValue,
            tabsRef,
            tabChecked: tabCheckedState, setTabChecked, setTabCheckedState,
            label, setLabel,
        }}>
            {props.children}
        </TabsContext.Provider>
    );
};


export { TabsProvider, TabsContext };
