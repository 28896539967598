import { ReactNode, useEffect, useState } from 'react';

import { Loader, LocalStorage, useGlobalState } from '@funfarm/kit';

import { IUser } from 'types';
import { getUser } from 'actions';

import { AuthLayout } from 'components/Layout';


export const authStorage = new LocalStorage({ storageKey: 'accessToken' });

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const accessToken = authStorage.getItem();

    const [user, setUser] = useGlobalState<IUser>('user');
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if(user || !accessToken)
            return;

        getUser()
            .then(user => {
                setUser(user);
            })
            .catch(() => authStorage.removeItem())
            .finally(() => setLoading(false));
    }, [accessToken, user, setUser]);


    if(loading && accessToken)
        return <Loader layout />

    return (
        <>
            {
                user ?
                    children :
                    <AuthLayout />
            }
        </>
    );
};

