import { useState } from 'react';

export let globalSate: Record<string, any> = {};


export const setGlobalState = <T = Record<string, any>>(keyName: string, state: T) => {
    globalSate = { ...globalSate, [keyName]: state };

    return state;
};


export const getGlobalState = <T = Record<string, any>>(key: string): T | undefined => {
    const scopes = key.split('.');
    let scopeA = globalSate;

    for(const f of scopes) {
        scopeA = scopeA[f];
    }

    return scopeA as T;
};


export const useGlobalState = <V = undefined>(keyName: string, initialValue?: V): [V | undefined, any] => {
    const [_, forceUpdate] = useState(0);

    const value = initialValue ?
        setGlobalState<V | undefined>(keyName, initialValue) :
        getGlobalState<V>(keyName);

    const setValue = async (value: V) => {
        await setGlobalState(keyName, value);
        forceUpdate(value => value + 1);
    };

    return [value, setValue];
};
