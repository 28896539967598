import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getEnumKeyByValue, Paper, Row, Tabs } from '@funfarm/kit';

import { EPeriod } from 'types';

import { DailyChart } from './DailyChart';
import { Indicators } from './Indicators';
import { NetworkChart } from './NetworkChart';

import css from './select.module.scss';


export const Select = () => {
    const { t } = useTranslation();

    const [period, setPeriod] = useState<keyof typeof EPeriod>(getEnumKeyByValue(EPeriod, EPeriod['current_month']));

    const onChangePeriod = (period: string | number) => {
        setPeriod(period as keyof typeof EPeriod);
    };


    return (
        <div className={css.pageGrid}>
            <Tabs tabChecked={period} onChange={onChangePeriod} className={css.tabs}>
                <Row gap={1} align="center" className={css.tabsHeader}>
                    <Tabs.Header index={'current_month'}>{t(EPeriod.current_month)}</Tabs.Header>
                    <Tabs.Header index={'prev_month'}>{t(EPeriod.prev_month)}</Tabs.Header>
                    <Tabs.Header index={'3m'}>{t(EPeriod['3m'])}</Tabs.Header>
                    <Tabs.Header index={'current_year'}>{t(EPeriod.current_year)}</Tabs.Header>
                    <Tabs.Header index={'prev_year'}>{t(EPeriod.prev_year)}</Tabs.Header>
                    <Tabs.Header index={'alltime'}>{t(EPeriod.alltime)}</Tabs.Header>
                </Row>

                <div className={css.tabsBody}>

                    <Indicators period={period} />

                    <Paper header={t('Daily')} className={css.dailyChart}>
                        <DailyChart period={period} />
                    </Paper>

                    <Paper header={t('Load by rooms')} className={css.loadChart}>
                        <NetworkChart period={period} />
                    </Paper>

                </div>
            </Tabs>
        </div>
    );
};
