import React, { CSSProperties, MouseEvent, ReactElement, useState } from 'react';
import classNames from 'classnames';

import noImage from './no-image.svg';

import css from './image.module.scss';


interface IProps {
    src: string,
    alt?: string,
    className?: string,
    fallBack?: string | ReactElement,
    onClick?: (event: MouseEvent<HTMLElement>) => void,
    style?: CSSProperties,
}

export const Image = (props: IProps) => {
    const { src, alt = src, className, fallBack, ...rest } = props;
    const [imageLoaded, setImageLoaded] = useState<boolean>(true);


    return (
        <>
            {
                imageLoaded ?
                    <img onError={() => setImageLoaded(false)} className={classNames(css.image, className)} src={src} alt={alt} {...rest} /> :
                    (
                        fallBack ?
                            (
                                typeof fallBack === 'string' ?
                                    <img src={fallBack} className={classNames(css.image, css.disabled, className)} alt={alt} {...rest} /> :
                                    React.cloneElement(fallBack, { className: classNames(css.image, css.disabled, fallBack.props.className, className), alt, ...rest })
                            ) :
                            // <IconNoImage button={false} className={classNames(css.image, css.disabled, className)} {...rest} alt={`Can't load ${alt}`} />
                            <img src={noImage} alt={`Can't load ${alt}`} className={classNames(css.image, css.disabled, className)} {...rest} />
                    )
            }
        </>
    );
};
