import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false, //!import.meta.env.PROD,
        resources: {},
        supportedLngs: ['ru', 'en', 'uk'],
        fallbackLng: 'en',
        load: 'languageOnly',
        ns: [],
        defaultNS: false,
        cleanCode: true,
        nonExplicitSupportedLngs: true,
        react: { useSuspense: true },
        initImmediate: false,
        interpolation: {
            escapeValue: false,
        }
    }, (err, t) => {
        if(err)
            console.error(err, t);
    });


// set locale for datepicker
import { Locale } from 'date-fns';
import { enUS } from 'date-fns/locale/en-US';
import { ru } from 'date-fns/locale/ru';
import { uk } from 'date-fns/locale/uk';

export const localeMap: Record<string, Locale> = { 'ru': ru, 'en': enUS, 'uk': uk };


import translations from 'locales';

i18n.addResourceBundle('en', 'default', translations.en, true);
i18n.addResourceBundle('ru', 'default', translations.ru, true);
i18n.addResourceBundle('uk', 'default', translations.uk, true);
i18n.setDefaultNamespace('default');

i18n.changeLanguage(i18n.language.substring(0, 2));


export default i18n;
