import React, { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title
} from "chart.js";
import { format } from "date-fns";

import { IError, Loader, Paper, roundDecimal, Row, Tabs } from "@funfarm/kit";

import { defaultChartOptions } from "helpers";
import i18n, { localeMap } from "helpers/i18n";
import { INetworkProfits } from "types";
import { getNetworksProfit } from "actions";

import { Helper } from "components/Helper";

import css from "./info.module.scss";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, Filler);


export const ProfitChart = () => {
    const { t } = useTranslation();

    const [room, setRoom] = useState<string>("all");

    const { isLoading, data } = useQuery<INetworkProfits, IError>({
        queryKey: ["info", "network-profit", room],
        queryFn: () => getNetworksProfit(room)
    });


    const onChangeRoom = (room: string) => {
        setRoom(room);
    };


    const chartData = useMemo(() => {
        return {
            labels: data?.progress.map((pr) => format(new Date(pr.month_start_date), "LLL yy", { locale: localeMap[i18n.language] })),
            datasets: [
                {
                    pointRadius: 0,
                    lineTension: 0.4,
                    fill: false,
                    borderColor: "#FFDC5D",
                    data: data?.progress.map(pr => roundDecimal(pr.profit))
                }
            ]
        };
    }, [data]);


    const chartOptions = useMemo(() => {
        return {
            ...defaultChartOptions,
            scales: {
                ...defaultChartOptions.scales,
                x: {
                    ...defaultChartOptions.scales?.x,
                    ticks: {
                        ...defaultChartOptions.scales?.x?.ticks,
                        callback: (_value: string, index: number) => chartData?.labels?.[index]
                    }
                }
            }
        };
    }, [chartData]);


    return (
        <Paper className={css.profitChart}>
            <Tabs tabChecked={room} onChange={(s)=>onChangeRoom(String(s))}>
                <Row spaceBetween>
                    <h3 className="row">
                        {t("Profit")}
                        <Helper path={`infoProfit`} />
                    </h3>
                    {
                        isLoading ?
                            <Loader layout size="small" style={{ color: "#FFDC5D" }} /> :
                            <Row gap={1} align="center" className={css.networks}>
                                {data?.rooms.map((r, k) => {
                                    return <Tabs.Header index={r} key={k}>{t(r)}</Tabs.Header>;
                                })}
                            </Row>
                    }
                </Row>

                {
                    isLoading ?
                        <Loader layout size="small" style={{ color: "#FFDC5D" }} /> :
                        <>
                            {data?.rooms.map((r, k) => {
                                return <Tabs.Body index={r} key={k}>
                                    <Line id={r} options={chartOptions as ChartOptions<"line">} data={chartData} />
                                </Tabs.Body>;
                            })}
                        </>
                }
            </Tabs>
        </Paper>
    );
};
