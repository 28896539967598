import React from 'react';

import { Icon, IconProps } from '../Icon';


export const IconDocumentSearch = (props: IconProps) => {
    return <Icon id="IconDocumentSearch" {...props}>
        <path d="M3 3C3 1.89543 3.89543 1 5 1H19C20.1046 1 21 1.89544 21 3V13.5C21 14.0523 20.5523 14.5 20 14.5C19.4477 14.5 19 14.0523 19 13.5V3H5V21H10.5C11.0523 21 11.5 21.4477 11.5 22C11.5 22.5523 11.0523 23 10.5 23H5C3.89544 23 3 22.1046 3 21V3Z" />
        <path d="M7.5 6C7.5 5.44772 7.94772 5 8.5 5H15.5C16.0523 5 16.5 5.44772 16.5 6C16.5 6.55228 16.0523 7 15.5 7H8.5C7.94772 7 7.5 6.55228 7.5 6Z" />
        <path d="M8.5 9C7.94772 9 7.5 9.44771 7.5 10C7.5 10.5523 7.94772 11 8.5 11H15.5C16.0523 11 16.5 10.5523 16.5 10C16.5 9.44771 16.0523 9 15.5 9H8.5Z" />
        <path d="M7.5 14C7.5 13.4477 7.94772 13 8.5 13H11.5C12.0523 13 12.5 13.4477 12.5 14C12.5 14.5523 12.0523 15 11.5 15H8.5C7.94772 15 7.5 14.5523 7.5 14Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16 15C14.067 15 12.5 16.567 12.5 18.5C12.5 20.433 14.067 22 16 22C16.6023 22 17.1698 21.8473 17.6649 21.5791L18.7929 22.7071C19.1834 23.0976 19.8166 23.0976 20.2071 22.7071C20.5976 22.3166 20.5976 21.6834 20.2071 21.2929L19.0791 20.1649C19.3473 19.6698 19.5 19.1023 19.5 18.5C19.5 16.567 17.933 15 16 15ZM17.5 18.5C17.5 18.9145 17.3332 19.2881 17.0607 19.5606C16.7882 19.8331 16.4145 20 16 20C15.1716 20 14.5 19.3284 14.5 18.5C14.5 17.6716 15.1716 17 16 17C16.8284 17 17.5 17.6716 17.5 18.5Z" />
    </Icon>;
};

export default IconDocumentSearch;
