import React from 'react';

import { Icon, IconProps } from '../Icon';

interface IconRadioProps extends IconProps {
    selected?: boolean
}

export const IconRadio = (props: IconRadioProps) => {
    return <Icon id="IconRadio" viewBox="0 0 20 20" {...props}>
        {
            props.selected &&
            <path d="M15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C12.7614 15 15 12.7614 15 10Z" />
        }
        <path fillRule="evenodd" clipRule="evenodd" d="M18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10ZM16.875 10C16.875 13.797 13.797 16.875 10 16.875C6.20304 16.875 3.125 13.797 3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20304 16.875 10Z" />
    </Icon>;
};

export default IconRadio;
