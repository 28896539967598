import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import i18n from 'helpers/i18n';

import css from './footer.module.scss';


interface IProps {
    className?: string,
}

export const Footer = (props: IProps) => {
    const { className } = props;
    const { t } = useTranslation();
    // const currentLang = i18n.language;
    const supportedLngs: Record<string, string> = { ru: 'RU', en: 'EN',/* uk: 'UA'*/ };


    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };


    return (
        <footer className={classNames(css.container, className)}>
            <div className={css.copy}>
                <span>&copy; 2024 FunFarm</span>
                <span>{import.meta.env.FF_VERSION}</span>
            </div>
            <nav>
                <a href="#">{t('Privacy policy')}</a>
                {
                    Object.keys(supportedLngs)
                        // .filter(lang => lang === currentLang)
                        .map(lang => <span key={lang} onClick={() => changeLanguage(lang)}>{supportedLngs[lang]}</span>)
                }
            </nav>
        </footer>
    );
};
