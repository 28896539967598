import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Error, IError,Input, Paper } from '@funfarm/kit';

import { authStorage } from 'helpers';
import { logIn } from 'actions';

import { Footer } from 'components/Footer';

import css from './authlayout.module.scss';


export const AuthLayout = () => {
    const { t } = useTranslation();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    const defaultValues = {
        name: '',
        password: '',
    };

    const {
        control,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues
    });

    const watch = useWatch({
        control
    });

    useEffect(() => {
        setError(undefined);
    }, [watch]);


    const onSubmit = (formData: typeof defaultValues) => {
        setSubmitted(true);

        formData.name = formData.name.trim();
        formData.password = formData.password.trim();

        logIn(formData)
            .then(response => {
                authStorage.setItem(response.access_token);
                window.location.reload();
            })
            .catch((error: IError) => {
                setError(error.message);
            })
            .finally(() => setSubmitted(false));
    };



    return (
        <div className={css.wrapper}>
            <div className={css.authPageContent}>
                <img src="/img/logo.svg" alt="FunFarm" className={css.logo} />

                <Paper className={css.container}>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate className={css.form}>
                        {
                            error &&
                            <Error message={error} view="box" />
                        }
                        <Controller
                            control={control}
                            name="name"
                            rules={
                                {
                                    required: t('User name is required'),
                                }
                            }
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    label={t('User name')}
                                    placeholder={t('Your Check login')}
                                    required
                                    error={!!errors.name} />
                            )} />
                        <Controller
                            control={control}
                            name="password"
                            rules={
                                {
                                    required: t('Password is required'),
                                }
                            }
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type="password"
                                    label={t('Password')}
                                    required
                                    error={!!errors.password} />
                            )} />
                        <Button type="submit" loading={submitted} disabled={!isValid}>{t('Login')}</Button>
                    </form>
                </Paper>

                <Footer className={css.footer} />
            </div>
        </div>
    );
};
