import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import css from './tabs.module.scss';


interface Props extends HTMLAttributes<HTMLDivElement> {
}

export const TabList = (props: Props) => {
    const {
        className, style,
        ...rest
    } = props;


    return (
        <div {...rest}
            className={classNames(
                css.tabList,
                className,
            )}
            style={style}
        >
            {props.children}
        </div>
    );
};

export default TabList;
