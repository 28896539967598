import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

import { Button, IError } from '@funfarm/kit';

import { WideLayout } from './';

import error from './error.module.scss';
import css from './layout.module.scss';


export const ErrorLayout = () => {
    const routerError = useRouteError();
    const navigate = useNavigate();
    const { status, statusText, message } = routerError as IError;


    const goBack = () => {
        navigate(-1);
    };


    const goHome = () => {
        navigate('/', { replace: true });
    };


    return (
        <div className={css.wrapper}>
            <div className={css.container}>
                <WideLayout>
                    <div className={error.container}>
                        <div className={error.body}>
                            <div className={error.label}>
                                <h2 className={error.wrong}>Something went wrong...</h2>
                            </div>
                            <div className={error.desc}>
                                <h4 className={error.error}>
                                    {
                                        (status || statusText) ?
                                            <>Error {status} {statusText}</> :
                                            routerError?.toString()
                                    }
                                </h4>
                                {
                                    // то что приходит с сервера
                                    message &&
                                    <p className={error.message}>{message}</p>
                                }
                            </div>
                            <div className={error.tip}>
                                Please go back or refresh the page
                            </div>
                            <div className={error.buttons}>
                                <Button view="text" onClick={goBack}>Go back</Button>
                                <Button view="text" onClick={goHome}>Go Home</Button>
                            </div>
                        </div>
                    </div>
                </WideLayout>
            </div>
        </div>
    );
};
