import React from 'react';

import { ISelectProps, Select as KitSelect } from '@funfarm/kit';

import css from './select.module.scss';


export const Select = (props: ISelectProps) => {
    return (
        <KitSelect className={css.select} labelPosition="inside" {...props} />
    );
};
