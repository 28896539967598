import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


interface IContextProps {
    children?: ReactNode,
    mobileExpanded?: boolean
    setMobileExpanded?: Dispatch<SetStateAction<boolean>>,
}

const defaultContextValue = {};

const NavigationContext = createContext<IContextProps>(defaultContextValue);

const NavigationProvider = (props: IContextProps) => {
    const [mobileExpanded, setMobileExpanded] = useState<boolean>(false);
    const location = useLocation();


    useEffect(() => {
        setMobileExpanded(false);
    }, [location]);

    return (
        <NavigationContext.Provider value={{
            mobileExpanded, setMobileExpanded
        }}>
            {props.children}
        </NavigationContext.Provider>
    );
};

export { NavigationProvider, NavigationContext };
